import React from "react"
import bg from "images/HeaderImage/chuttersnap-9G1kXjqOq4U-unsplash.jpg"



const EnConstruction = () => (
  <div style={{
    width: '100%',
    position: 'fixed',
    height: '100%',
    top: '0',
    left: '0',
    backgroundColor: '#000',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundImage: `url(${bg})`,
    backgroundSize: 'cover'
  }}>

    <div style={{width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    backgroundColor: 'rgba(0,0,0,0.35)'}}>
      <h1 style={{color: "white", fontSize: '6em', lineHeight: '1em'}}>En construction</h1>
      <p style={{color: "white", fontSize: '3em', lineHeight: '1em'}}>Cette page est en cours de construction.</p>
    </div>
  </div>
)

export default EnConstruction
